$page-header-height: 5rem;
$shrunk-page-header-ratio: 0.7;
$shrunk-page-header-height: calc(#{$page-header-height} * #{$shrunk-page-header-ratio});
$odd-section-title-underline-color: #64b06d;
$even-section-title-underline-color: #6f3ba3;
$off-work-project-tile-hover-scale: 1.07;
$section-horizontal-padding: 64px;
$min-layout-section-horizontal-padding: 32px;
$min-layout-1-max-width: 1023px;
$min-layout-2-max-width: 800px;
$min-layout-3-max-width: 650px;
