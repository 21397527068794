@import "../../../style/variables";

.NavigationLink {
  text-decoration: none;
  outline: 0;
  color: #ffffff;
  font-weight: 750;
  padding: 0.8em 1.5em;
  border-radius: 1em;
  display: inline-block;

  &:hover {
    background-color: rgba(69, 199, 255, 0.25);

    @media (hover: none), (pointer: coarse) {
      background-color: inherit;
    }
  }

  &.Focused {
    color: #45c7ff;
  }
}
