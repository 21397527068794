@import "../../../style/variables";

$global-padding: 30px;

.ProjectModalView {
  background-color: #ffffff;
  color: #000000;
  padding: $global-padding;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  font-size: 1rem;

  .Title {
    margin: 0 0 0.5em 0;
    flex-shrink: 0;
  }

  .Description {
    margin: 0 0 1em 0;
    flex-shrink: 0;
    max-width: 100%;
    padding: 0.5em;
    border: 1px dashed #d0d0d0;
    border-radius: 0.5em;
  }

  &.ProjectModalViewWithImages {
    height: 90vh;
    width: 80vw;

    @media (max-width: $min-layout-3-max-width) {
      width: 90vw;
    }

    .Description {
      max-height: 7em;
      overflow-y: scroll;

      @media (max-aspect-ratio: 1/1) {
        max-height: 10em;
      }
    }
  }

  .ImageCarouselContainer {
    min-height: 0;
    flex-grow: 1;
    width: 100%;
    height: 100%;
  }

  .ViewedImageInfo {
    position: absolute;
    left: $global-padding;
    bottom: $global-padding;
  }

  .CloseButton {
    flex-shrink: 0;
    border: 0;
    border-radius: 0.8em;
    outline: 0;
    padding: 0.5em 1.5em;
    background-color: #224d73;
    color: #ffffff;
    cursor: pointer;
    margin: 0.75em 0 0 0;
    font-size: 1em;

    &:hover {
      background-color: #2c6496;
    }
  }
}
