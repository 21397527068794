@import "../../style/variables";

$scroll-button-width: 60px;
$scroll-button-height: 120px;
$arrow-image-size: 40px;
$scroll-button-border-radius: 15px;
$min-layout-scroll-button-width: 40px;
$min-layout-scroll-button-height: 80px;
$min-layout-arrow-image-size: 26.5px;
$min-layout-scroll-button-border-radius: 10px;

.Carousel {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
  line-height: 0;

  .CarouselTrack {
    height: 100%;
    width: 100%;
    transition: all 0.2s;
    display: inline-block;
    touch-action: pinch-zoom;

    &.CarouselTrackDragging {
      transition: none;
    }

    .CarouselImageContainer {
      height: 100%;
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;

      .CarouselImage {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  .ScrollButton {
    border: 0;
    outline: 0;
    cursor: pointer;
    position: absolute;
    width: $scroll-button-width;
    height: $scroll-button-height;
    top: calc(50% - (#{$scroll-button-height} * 0.5));
    border-radius: $scroll-button-border-radius;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    text-align: center;
    opacity: 0.7;
    transition: all 0.2s;

    @media (max-width: $min-layout-3-max-width) {
      width: $min-layout-scroll-button-width;
      height: $min-layout-scroll-button-height;
      top: calc(50% - (#{$min-layout-scroll-button-height} * 0.5));
      border-radius: $min-layout-scroll-button-border-radius;
    }

    &.ScrollButtonLeft {
      left: 0;
    }

    &.ScrollButtonRight {
      right: 0;
    }

    &.HiddenScrollButton {
      visibility: hidden;
    }

    &:hover {
      opacity: 1.0;

      @media (hover: none), (pointer: coarse) {
        opacity: 0.7;
      }
    }

    .ArrowImage {
      width: $arrow-image-size;
      height: $arrow-image-size;
      vertical-align: middle;

      @media (max-width: $min-layout-3-max-width) {
        width: $min-layout-arrow-image-size;
        height: $min-layout-arrow-image-size;
      }
    }
  }
}
