@import "../../../style/variables";

.MainMenu {
  position: fixed;
  z-index: 200;
  height: 100vh;
  top: 0;
  width: 100vw;
  left: 100vw;
  transition: all 0.2s;

  &.Visible {
    left: 0;
  }

  .MenuContainer {
    position: absolute;
    height: calc(100vh - #{$page-header-height});
    top: $page-header-height;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    transition: inherit;

    @media (max-width: $min-layout-3-max-width) {
      height: calc(100vh - #{$shrunk-page-header-height});
      top: $shrunk-page-header-height;
    }
  }

  &.ShrunkNavigationBar {
    .MenuContainer {
      height: calc(100vh - #{$shrunk-page-header-height});
      top: $shrunk-page-header-height;
    }
  }
}
