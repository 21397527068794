@import "../../style/variables";

$transition-time: 0.2s;

.Overlay {
  visibility: hidden;
  opacity: 0;
  transition: all $transition-time;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: auto;

  .ContentContainer {
    transform: scale(0.7);
    transition: all $transition-time;
    background-color: rgba(0, 0, 0, 0.0);
    margin: 0;
    padding: 0;
  }

  &.Visible {
    visibility: visible;
    opacity: 1.0;

    .ContentContainer {
      transform: scale(1.0);
    }
  }
}
