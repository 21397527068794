@import "../../style/variables";

.NavigationBar {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: $page-header-height;

  background-color: rgba(22, 49, 74, 0.75);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  padding: 0 2.5em;
  transition: all 0.3s;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &.Shrunk {
    height: $shrunk-page-header-height;
    font-size: #{$shrunk-page-header-ratio}em;
    background-color: rgba(22, 49, 74, 0.95);

    .MenuButton {
      height: calc((#{$page-header-height} - 2.5rem) * #{$shrunk-page-header-ratio});
    }
  }

  @media (max-width: $min-layout-3-max-width) {
    height: $shrunk-page-header-height;
    font-size: #{$shrunk-page-header-ratio}em;
  }

  .NameLabel {
    font-size: 2.2em;
    font-weight: bolder;
    color: #ffffff;
    margin: 0;
  }

  .NavigationLinksContainer {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $min-layout-1-max-width) {
      display: none;
    }
  }

  .MenuButton {
    height: calc(#{$page-header-height} - 2.5rem);
    padding: 0;
    margin: 0;
    outline: 0;
    border: 0;
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    transition: inherit;
    display: none;

    @media (max-width: $min-layout-1-max-width) {
      display: inline-block;
    }

    @media (max-width: $min-layout-3-max-width) {
      height: calc((#{$page-header-height} - 2.5rem) * #{$shrunk-page-header-ratio});
    }

    .MenuButtonImage {
      height: 100%;
      vertical-align: middle;
    }
  }

  .MenuNavigationLinksContainer {
    list-style: none;
    margin: 1em 0 0 0;
    padding: 0;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
