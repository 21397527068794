@import "../../../style/variables";

$transition-time: 0.2s;

.ProjectTile {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 2em;
  cursor: pointer;
  border: 0;
  border-radius: 15px;
  outline: 0;
  background: linear-gradient(135deg, #2e6799, #21486e);
  color: #ffffff;
  transition: all $transition-time;
  box-shadow: 5px 5px 10px #505050;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;

  &:hover {
    transform: scale($off-work-project-tile-hover-scale);

    @media (hover: none), (pointer: coarse) {
      transform: none;
    }
  }

  .Title {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    text-align: center;
    text-overflow: ellipsis;
  }

  .ProjectImageContainer {
    flex-grow: 1;
    width: 100%;
    margin-top: 1em;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
