@import "../../../style/variables";

$background-1-grad-color-1: #224d73;
$background-1-grad-color-2: #16314a;
$background-2-grad-color-1: #c0c0c0;
$background-2-grad-color-2: #ffffff;
$background-grad-color-trans: 0.8;
$background-1-grad-color-1-trans: rgba(34, 77, 115, $background-grad-color-trans);
$background-1-grad-color-2-trans: rgba(22, 49, 74, $background-grad-color-trans);
$background-2-grad-color-1-trans: rgba(192, 192, 192, $background-grad-color-trans);
$background-2-grad-color-2-trans: rgba(255, 255, 255, $background-grad-color-trans);
$title-underline-width: 3px;

.Section {
  width: 100%;
  padding: 3em $section-horizontal-padding;
  display: flex;
  flex-direction: column;
  position: relative;
  // font-size: 1.2em;
  background: linear-gradient(135deg, $background-1-grad-color-1, $background-1-grad-color-2);
  color: #ffffff;

  @media (max-width: $min-layout-3-max-width) {
    padding: 2em $min-layout-section-horizontal-padding;
  }

  .ScrollAnchor {
    visibility: hidden;
    position: absolute;
    top: calc(#{$shrunk-page-header-height} * (-1));
  }

  .SectionHeader {
    width: 100%;
    display: flex;
    justify-content: center;

    .SectionTitleContainer {
      padding: 0 2em 0.8em;
      border-bottom: $title-underline-width solid $odd-section-title-underline-color;
      margin: 0 0 1em;

      .SectionTitle {
        margin: 0;
        color: #e0e0e0;
      }
    }
  }

  &:nth-child(even) {
    background: linear-gradient(225deg, $background-2-grad-color-1, $background-2-grad-color-2);
    color: #000000;

    .SectionHeader {
      .SectionTitleContainer {
        border-bottom: $title-underline-width solid $even-section-title-underline-color;
        .SectionTitle {
          color: #404040;
        }
      }
    }
  }

  .Backdrop {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -100;
    background-size: cover;
    background-color: #000000;
  }

  &.WithBackdrop {
    background: linear-gradient(135deg, $background-1-grad-color-1-trans, $background-1-grad-color-2-trans);

    &:nth-child(even) {
      background: linear-gradient(225deg, $background-2-grad-color-1-trans, $background-2-grad-color-2-trans);
    }
  }
}
