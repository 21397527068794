@import "../../style/variables";

$avatar-size: 240px;
$avatar-border: 10px;
$total-avatar-size: calc(#{$avatar-size} + (2 * #{$avatar-border}));
$min-layout-avatar-size: 170px;
$min-layout-avatar-border: 7px;
$min-layout-total-avatar-size: calc(#{$min-layout-avatar-size} + (2 * #{$min-layout-avatar-border}));

.Sections {
  width: 100%;
  padding: 0;
}

.ColumnarSubSectionsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;

  .ColumnarSubSection {
    width: 50%;

    &:first-child {
      margin-right: 1em;
    }
    &:last-child {
      margin-left: 1em;
    }
  }

  @media (max-width: $min-layout-3-max-width) {
    flex-direction: column;

    &.MinLayoutReverse {
      flex-direction: column-reverse;
    }

    .ColumnarSubSection {
      width: 100%;

      &:first-child {
        margin: 0;
      }
      &:last-child {
        margin: 0;
      }
    }
  }
}

#IntroductionSection {
  @media (max-width: $min-layout-1-max-width) {
    .ColumnarSubSectionsContainer {
      flex-direction: column;

      &.MinLayoutReverse {
        flex-direction: column-reverse;
      }

      .ColumnarSubSection {
        width: 100%;

        &:first-child {
          margin: 0;
        }
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

.CenteredSubSection {
  max-width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
}

.BulletList {
  li {
    margin-top: 0.5em;
  }
}

.Icon {
  font-size: 1.2em;
}

#MainTitleSection {
  height: 100vh;
  padding: 0;
}

.MainTitleContainer {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .MainTitle {
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .BlockBracket {
      margin: 0 3em;
      width: 4em;
      align-self: stretch;
    }

    .AvatarAndTitleContainer {
      display: flex;
      flex-direction: row;
      align-items: center;

      @media (max-width: $min-layout-2-max-width) {
        flex-direction: column;
      }

      .AvatarImage {
        border: $avatar-border solid rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        height: $total-avatar-size;
        width: $total-avatar-size;

        @media (max-width: $min-layout-3-max-width) {
          border-width: $min-layout-avatar-border;
          height: $min-layout-total-avatar-size;
          width: $min-layout-total-avatar-size;
        }
      }

      .TitleContainer {
        margin-left: 4em;
        text-shadow: 1px 1px 3px #000000;

        @media (max-width: $min-layout-2-max-width) {
          flex-direction: column;
          margin-left: 0;
          margin-top: 1em;
        }

        .PreTitle {
          font-size: 1.8em;
          font-style: italic;
          margin: 0.35em 0 0 0;

          @media (max-width: $min-layout-2-max-width) {
            text-align: center;
          }
          @media (max-width: $min-layout-3-max-width) {
            font-size: 1.25em;
          }
        }

        .Title {
          font-size: 3.6em;
          margin: 0.35em 0 0 0;
          border-style: solid;
          border-width: 0 0 3px 0;
          border-image-source: linear-gradient(90deg, $odd-section-title-underline-color, $even-section-title-underline-color);
          border-image-slice: 0 0 100% 0;
          padding-bottom: 10px;

          @media (max-width: $min-layout-3-max-width) {
            font-size: 2.6em;
          }
        }
      }
    }
  }
}

.IntroductoryClipContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .IntroductoryClipBorderContainer {
    border: 12px solid rgba(34, 77, 115, 0.5);
    border-radius: 10px;
    line-height: 0;

    .IntroductoryClip {
      width: 404px;
      height: 404px;

      @media (max-width: $min-layout-1-max-width) {
        width: 100%;
        height: auto;
      }
    }
  }

  .IntroductoryClipSizeNote {
    font-size: 0.8em;
    font-weight: bold;
    margin-top: 0.8em;
  }
}

.FuturePlansTitleImage {
  height: 1.5em;
  margin-left: 0.5em;
  vertical-align: middle;
}

.OffWorkProjectsContainer {
  list-style: none;
  margin: 1em 0 0 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .ProjectTileContainer {
    position: relative;
    width: calc((100% / 3) / #{$off-work-project-tile-hover-scale});
    padding-top: calc((100% / 3) / #{$off-work-project-tile-hover-scale});
    margin: calc((((100% / 3) / #{$off-work-project-tile-hover-scale}) * (#{$off-work-project-tile-hover-scale} - 1)) / 2) 0;

    @media (max-width: $min-layout-1-max-width) {
      width: calc((100% / 2) / #{$off-work-project-tile-hover-scale});
      padding-top: calc((100% / 2) / #{$off-work-project-tile-hover-scale});
      margin: calc((((100% / 2) / #{$off-work-project-tile-hover-scale}) * (#{$off-work-project-tile-hover-scale} - 1)) / 2) 0;
    }

    @media (max-width: $min-layout-3-max-width) {
      width: calc(100% / #{$off-work-project-tile-hover-scale});
      padding-top: calc(100% / #{$off-work-project-tile-hover-scale});
      margin: calc(((100% / #{$off-work-project-tile-hover-scale}) * (#{$off-work-project-tile-hover-scale} - 1)) / 2) 0;
    }

    .ProjectTileInnerContainer {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      > * {
        flex-grow: 0;
        flex-shrink: 0;
      }
    }
  }
}

#ContactSection {
  .ContactLinksContainer {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 2em;

    .ContactLink {
      margin-top: 1.5em;
      color: #ffffff;
      text-decoration: none;

      .ContactLinkIcon {
        margin-right: 1.2em;
        width: 2em;
        height: 2em;
        vertical-align: middle;

        .ContactLinkIconSVGPath {
          fill: #ffffff;
        }
      }

      .ContactText {
        vertical-align: middle;
      }

      &:hover {
        color: #45c7ff;

        .ContactLinkIconSVGPath {
          fill: #45c7ff;
        }
      }
    }
  }
}
